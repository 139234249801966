import React from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';
import Row from 'react-bootstrap/Row';

const propTypes = {
  children: node.isRequired,
  className: string,
};

const defaultProps = {
  className: undefined,
};

const BigCard = ({ children, className, ...props }) => {
  const classes = cx('c-big-card bg-white gap-5 gap-lg-0', className);

  return (
    <Row className={classes} {...props}>
      {children}
    </Row>
  );
};

BigCard.propTypes = propTypes;
BigCard.defaultProps = defaultProps;

export default BigCard;
