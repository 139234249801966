import React from 'react';
import { node, string } from 'prop-types';
import cx from 'classnames';
import Col from 'react-bootstrap/Col';

const propTypes = {
  col: string.isRequired,
  children: node.isRequired,
  className: string,
};

const defaultProps = {
  className: undefined,
};

const BigCardItem = ({ col, children, className }) => {
  const classes = cx('c-big-card-item', className);

  return (
    <Col className={classes} lg={col}>
      {children}
    </Col>
  );
};

BigCardItem.propTypes = propTypes;
BigCardItem.defaultProps = defaultProps;

export default BigCardItem;
