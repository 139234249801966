import React from 'react';
import { useMedia } from 'react-use';
import { StaticImage } from 'gatsby-plugin-image';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/Layout/Layout';
import HeroParticles from '../components/HeroParticles/HeroParticles';
import Section from '../components/Section/Section';
import BigCard from '../components/Cards/BigCard';
import BigCardItem from '../components/Cards/BigCardItem';
import IconList from '../components/List/IconList';
import IconListItem from '../components/List/IconListItem';
import SimpleCard from '../components/Cards/SimpleCard';
import SimpleCardItem from '../components/Cards/SimpleCardItem';
import IconText from '../components/IconText/IconText';
import IconButton from '../components/IconButton/IconButton';
import TableWrapper from '../components/Table/TableWrapper';
import TableHead from '../components/Table/TableHead';
import TableHeadItem from '../components/Table/TableHeadItem';
import TableBody from '../components/Table/TableBody';
import TableBodyRow from '../components/Table/TableBodyRow';
import TableBodyItem from '../components/Table/TableBodyItem';
import TableDropdown from '../components/Table/TableDropdown';
import TableDropdownItem from '../components/Table/TableDropdownItem';
import Banner from '../components/Banner/Banner';
import Faqs from '../components/Faqs/Faqs';
import FaqSingle from '../components/Faqs/FaqSingle';
import Seo from '../components/Seo';

import banner from '../images/banner-2.png';
import elevateIcon from '../images/elevate-icon.svg';
import boostPatienSatisfaction from '../images/boost-patient-satisfaction.svg';
import boostPatienSatisfactionMobile from '../images/boost-patient-satisfaction-mobile.svg';
import growAppointmentVolume from '../images/grow-appointment-volume.svg';
import growAppointmentVolumeMobile from '../images/grow-appointment-volume-mobile.svg';
import increaseProductivityStreamlineOperations from '../images/increase-productivity-streamline-operations.svg';
import increaseProductivityStreamlineOperationsMobile from '../images/increase-productivity-streamline-operations-mobile.svg';
import increaseRevenue from '../images/increase-revenue.svg';
import increaseRevenueMobile from '../images/increase-revenue-mobile.svg';
import automateWorkflows from '../images/automate-workflows.svg';
import automateWorkflowsMobile from '../images/automate-workflows-mobile.svg';
import buildYourPatient from '../images/build-your-patient.svg';
import buildYourPatientMobile from '../images/build-your-patient-mobile.svg';
import clockCircleIcon from '../images/clock-circle-icon.svg';
import calendarIcon from '../images/calendar-icon.svg';
import warningIcon from '../images/warning-icon.svg';

const PatientSchedulingSoftware = () => {
  const isDesktop = useMedia('(min-width: 1024px)', true);

  return (
    <Layout hasNavBg>
      <Seo
        title="Patient Self-Scheduling Software | Bridge"
        description="Self-scheduling allows patients to easily book appointments, 24/7, without having to call an office. Learn how Bridge Scheduling can help your practice."
        canonical="/patient-scheduling-software/"
      />

      <HeroParticles
        title="Patient Self-Scheduling Software"
        subTitle="Take the hassle out of appointment scheduling with a robust self-scheduling solution for desktop and mobile that integrates into your EHR/PMS system."
        label="Request a Demo Now"
        uri="/view-a-demo/"
      />

      <Section variant="no-pt-top">
        <Container>
          <Row className="align-items-center">
            <Col className="mb-5 mb-lg-0" lg={6}>
              <StaticImage
                src="../images/patient-self-scheduling-software-hero.png"
                alt=""
                className="w-100 rounded-xl"
              />
            </Col>
            <Col lg={6}>
              <p className="font-size-lg">
                <strong>Make a great first impression.</strong> Scheduling an
                appointment is often the first interaction patients have with
                your business.
              </p>
              <p className="font-size-lg">
                With Bridge Scheduling, patients can book appointments at their
                convenience, without having to call your office. Self-scheduling
                not only reduces administrative burden for your practice, it
                also shows patients that you value their convenience, their
                loyalty, and their{' '}
                <a
                  href="https://pmc.ncbi.nlm.nih.gov/articles/PMC8790681/"
                  target="_blank"
                  rel="noreferrer"
                >
                  trust
                </a>
                . Create a positive experience from the very first interaction,
                with Bridge Scheduling.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section variant="colors-wave">
        <Container className="position-relative">
          <Row>
            <Col className="c-big-card-container d-flex flex-column">
              <BigCard>
                <BigCardItem col="7" hasImgRight={false}>
                  <div className="d-flex flex-column gap-5">
                    <div className="d-flex flex-column gap-4">
                      <h2 className="text-orange fw-bold mb-0">
                        Boost Patient Satisfaction
                      </h2>
                      <h3 className="fw-bold mb-0">
                        Patient demand for the convenience of self-scheduling is
                        growing
                      </h3>
                      <div>
                        <p className="font-size-sm">
                          If you’re scheduling patient appointments over the
                          phone, you could be creating a barrier for patients to
                          access your services.
                        </p>
                        <p className="font-size-sm mb-0">
                          With online patient self-scheduling, patients can
                          quickly and easily make appointments, on their own
                          time.
                        </p>
                      </div>
                      <p className="font-size-sm mb-0">
                        <strong>
                          How can Bridge Scheduling boost patient satisfaction?
                        </strong>
                      </p>
                    </div>
                    <div className="d-flex flex-column gap-4">
                      <IconList className="d-flex flex-column gap-4 m-0">
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>Empower patients</strong> with a
                              user-friendly system that lets them manage their
                              appointments, making it easier to schedule,
                              reschedule, or cancel as needed.
                            </>
                          }
                        />
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>Provide a personalized</strong> experience
                              with an intuitive design that allows patients to
                              choose their preferred provider, location, and
                              appointment type quickly.
                            </>
                          }
                        />
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>Offer flexibility and convenience</strong>{' '}
                              by allowing patients to schedule their
                              appointments 24/7, eliminating the need for phone
                              calls and giving them control over their schedule.
                            </>
                          }
                        />
                      </IconList>
                    </div>
                    <SimpleCard>
                      <SimpleCardItem
                        col="12"
                        title="Increased complexity"
                        text={
                          <>
                            In a patient experience survey,{' '}
                            <a
                              href="https://www.softwareadvice.com/resources/patient-self-scheduling/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              31% of patients
                            </a>{' '}
                            reported that appointment scheduling was the biggest
                            hassle of their last doctor’s visit.
                          </>
                        }
                        showTitle={false}
                        iconRight={elevateIcon}
                        showIconRight
                      />
                    </SimpleCard>
                  </div>
                </BigCardItem>
                <BigCardItem col="5">
                  <img
                    src={boostPatienSatisfaction}
                    alt=""
                    className="c-big-card-img w-100 d-none d-md-block"
                  />
                  <img
                    src={boostPatienSatisfactionMobile}
                    alt=""
                    className="w-100 d-md-none"
                  />
                </BigCardItem>
              </BigCard>

              <BigCard className="has-img-left flex-column-reverse flex-lg-row">
                <BigCardItem col="5">
                  <img
                    src={growAppointmentVolume}
                    alt=""
                    className="c-big-card-img w-100 d-none d-md-block"
                  />
                  <img
                    src={growAppointmentVolumeMobile}
                    alt=""
                    className="w-100 d-md-none"
                  />
                </BigCardItem>
                <BigCardItem col="7">
                  <div className="d-flex flex-column gap-5">
                    <div className="d-flex flex-column gap-4">
                      <h2 className="text-orange fw-bold mb-0">
                        Grow Appointment Volume
                      </h2>
                      <h3 className="fw-bold mb-0">
                        Grow your patient base by attracting new patients and
                        retaining existing patients
                      </h3>
                      <p className="font-size-sm mb-0">
                        <strong>
                          How can Bridge Scheduling help you grow appointment
                          volume?
                        </strong>
                      </p>
                    </div>
                    <div className="d-flex flex-column gap-4">
                      <IconList className="d-flex flex-column gap-4 m-0">
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>Reduce no-shows and cancellations</strong>{' '}
                              by sending automated reminders and confirmations
                              through SMS, email, or portal messages, ensuring
                              patients stay on track and show up for their
                              appointments.
                            </>
                          }
                        />
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>Increase patient retention</strong> with
                              automatic rebooking reminders for follow-up and
                              recurring appointments, making it easier for
                              patients to stay engaged with your practice and
                              schedule future visits.
                            </>
                          }
                        />
                      </IconList>
                    </div>
                    <SimpleCard>
                      <SimpleCardItem
                        col="12"
                        title="Increased complexity"
                        text={
                          <>
                            Providers who offer online self-scheduling may
                            experience as much as{' '}
                            <a
                              href="https://business.yocale.com/blog/2021-online-booking-stats-original-research/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              26% more new customers
                            </a>{' '}
                            compared to providers that don’t. Research also
                            shows that re-booking reminders may lead to as much
                            as a{' '}
                            <strong>30% growth in repeat appointments.</strong>
                          </>
                        }
                        showTitle={false}
                        iconRight={elevateIcon}
                        showIconRight
                      />
                    </SimpleCard>
                  </div>
                </BigCardItem>
              </BigCard>

              <BigCard>
                <BigCardItem col="7" hasImgRight={false}>
                  <div className="d-flex flex-column gap-5">
                    <div className="d-flex flex-column gap-4">
                      <h2 className="text-orange fw-bold mb-0">
                        Increase Productivity and Streamline operations
                      </h2>
                      <h3 className="fw-bold mb-0">
                        Eliminate labor-intensive scheduling-related tasks so
                        your staff can focus on patient care
                      </h3>
                      <div>
                        <p className="font-size-sm mb-0">
                          Integrate Bridge Scheduling with your current EHR, PM,
                          and/or RCM so patients can book appointments based on
                          real-time provider availability. EHR integration leads
                          to streamlined administrative workflow, allowing staff
                          to focus on more complex activities and patient care.
                        </p>
                      </div>
                      <p className="font-size-sm mb-0">
                        <strong>
                          How can Bridge Scheduling features help you increase
                          productivity?
                        </strong>
                      </p>
                    </div>
                    <div className="d-flex flex-column gap-4">
                      <IconList className="d-flex flex-column gap-4 m-0">
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>Reduce scheduling conflicts</strong> by
                              fully integrating your EHR, PM, and/or RCM and
                              allowing patients to self-schedule based on
                              accurate, up-to-date availability.
                            </>
                          }
                        />
                      </IconList>
                    </div>
                    <SimpleCard>
                      <SimpleCardItem
                        col="12"
                        title="Increased complexity"
                        text={
                          <>
                            Research tells us that it takes less than 1 minute
                            for a patient to book a medical appointment online,
                            compared to{' '}
                            <a
                              href="https://newsroom.accenture.com/news/2014/two-in-three-patients-will-book-medical-appointments-online-in-five-years-accenture-predicts"
                              target="_blank"
                              rel="noreferrer"
                            >
                              8.1 minutes
                            </a>{' '}
                            for a patient to book an appointment over the phone.
                          </>
                        }
                        showTitle={false}
                        iconRight={elevateIcon}
                        showIconRight
                      />
                    </SimpleCard>
                  </div>
                </BigCardItem>
                <BigCardItem col="5">
                  <img
                    src={increaseProductivityStreamlineOperations}
                    alt=""
                    className="c-big-card-img w-100 d-none d-md-block"
                  />
                  <img
                    src={increaseProductivityStreamlineOperationsMobile}
                    alt=""
                    className="w-100 d-md-none"
                  />
                </BigCardItem>
              </BigCard>

              <BigCard className="has-img-left flex-column-reverse flex-lg-row">
                <BigCardItem col="5">
                  <div>
                    <img
                      src={increaseRevenue}
                      alt=""
                      className="c-big-card-img w-100 d-none d-md-block"
                    />
                    <img
                      src={increaseRevenueMobile}
                      alt=""
                      className="w-100 d-md-none"
                    />
                  </div>
                </BigCardItem>
                <BigCardItem col="7">
                  <div className="d-flex flex-column gap-5">
                    <div className="d-flex flex-column gap-4">
                      <h2 className="text-orange fw-bold mb-0">
                        Increase Revenue
                      </h2>
                      <h3 className="fw-bold mb-0">
                        Reduce the risk of no-shows and cut scheduling-related
                        staff costs
                      </h3>
                      <div>
                        <p className="font-size-sm mb-0">
                          Patient scheduling software allows patients to choose
                          appointment times that fit their availability, which
                          means patients are more likely to show up for
                          appointments. Automatic appointment confirmations and
                          reminders decrease the likelihood that patients will
                          forget appointments and eliminate the need for staff
                          to handle scheduling-related phone calls and manage
                          rescheduling.
                        </p>
                      </div>
                      <p className="font-size-sm mb-0">
                        <strong>
                          How can Bridge Scheduling help you increase revenue?
                        </strong>
                      </p>
                    </div>
                    <div className="d-flex flex-column gap-4">
                      <IconList className="d-flex flex-column gap-4 m-0">
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>Reduce missed appointments</strong> with
                              automated reminders and confirmations via SMS,
                              email, or portal messages.
                            </>
                          }
                        />
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>Encourage more bookings</strong> by
                              offering an easy-to-use platform for patients to
                              schedule, reschedule, or cancel appointments.
                            </>
                          }
                        />
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>Capture more appointments</strong> by
                              allowing patients to book 24/7, on their own
                              schedule, without the need to call your office.
                            </>
                          }
                        />
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>Maximize provider availability</strong> by
                              giving patients access to real-time availability
                              with seamless EHR, PM, or RCM systems integration,
                              allowing you to see more patients during the day.
                            </>
                          }
                        />
                      </IconList>
                    </div>
                    <SimpleCard>
                      <SimpleCardItem
                        col="12"
                        title="Increased complexity"
                        text={
                          <>
                            Providers who use patient self-scheduling may be
                            able to reduce no-shows by{' '}
                            <a
                              href="https://www.beckershospitalreview.com/healthcare-information-technology/patient-access-and-engagement-insights-from-two-million-appointments.html"
                              target="_blank"
                              rel="noreferrer"
                            >
                              up to 17%
                            </a>
                            .
                          </>
                        }
                        showTitle={false}
                        iconRight={elevateIcon}
                        showIconRight
                      />
                    </SimpleCard>
                  </div>
                </BigCardItem>
              </BigCard>

              <BigCard>
                <BigCardItem col="7" hasImgRight={false}>
                  <div className="d-flex flex-column gap-5">
                    <div className="d-flex flex-column gap-4">
                      <h2 className="text-orange fw-bold mb-0">
                        Automate Workflows
                      </h2>
                      <h3 className="fw-bold mb-0">
                        Customize and automate complex self-scheduling workflows
                      </h3>
                      <p className="font-size-sm mb-0">
                        Run your practice exactly how you want. Create and
                        automate custom scheduling rules based on your
                        administrative preferences and scheduling decision
                        trees.
                      </p>
                    </div>
                    <div className="d-flex flex-column gap-5">
                      <Row className="gap-5 gap-lg-0">
                        <Col lg={6}>
                          <IconText icon={clockCircleIcon}>
                            <p className="font-size-base fw-bold mb-0">
                              Create time buffers between certain appointment
                              types
                            </p>
                          </IconText>
                        </Col>
                        <Col lg={6}>
                          <IconText icon={calendarIcon}>
                            <p className="font-size-base fw-bold mb-0">
                              Schedule appointments of different lengths for new
                              vs. returning patients
                            </p>
                          </IconText>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mx-lg-auto" lg={6}>
                          <IconText icon={warningIcon}>
                            <p className="font-size-base fw-bold mb-0">
                              Leave available slots for urgent, high-priority,
                              and same-day appointments
                            </p>
                          </IconText>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </BigCardItem>
                <BigCardItem col="5">
                  <img
                    src={automateWorkflows}
                    alt=""
                    className="c-big-card-img w-100 d-none d-md-block"
                  />
                  <img
                    src={automateWorkflowsMobile}
                    alt=""
                    className="w-100 d-md-none"
                  />
                </BigCardItem>
              </BigCard>

              <BigCard className="has-img-left flex-column-reverse flex-lg-row">
                <BigCardItem col="5">
                  <img
                    src={buildYourPatient}
                    alt=""
                    className="c-big-card-img w-100 d-none d-md-block"
                  />
                  <img
                    src={buildYourPatientMobile}
                    alt=""
                    className="w-100 d-md-none"
                  />
                </BigCardItem>
                <BigCardItem col="7">
                  <div className="d-flex flex-column gap-5">
                    <div className="d-flex flex-column align-items-start gap-4">
                      <h2 className="text-orange fw-bold mb-0">
                        Build Your Patient Engagement Solution, One Module At A
                        Time
                      </h2>
                      <div>
                        <p className="font-size-sm">
                          <strong>
                            Consolidate Bridge modules to create a comprehensive
                            suite of patient-led tools. Consolidated digital
                            solutions help encourage patient engagement and
                            promote operational efficiency.
                          </strong>
                        </p>
                        <p className="font-size-sm">
                          Bridge Scheduling can be integrated and consolidated
                          with other Bridge modules as part of your overall{' '}
                          <a href="/patient-engagement-solution/">
                            patient engagement solution
                          </a>
                          .
                        </p>
                        <p className="font-size-sm mb-0">
                          Integrate Bridge Scheduling with the Bridge Intake
                          module to facilitate the completion of intake forms,
                          before patients arrive at your office. With Bridge
                          Intake, patients can fill out necessary intake
                          information online, after booking their appointment.
                        </p>
                      </div>
                      <IconButton
                        className="c-icon-button__big"
                        href="/patient-intake-solution/"
                      >
                        Learn More About Bridge Intake{' '}
                        <FaArrowRight size={16} className="ms-2" />
                      </IconButton>
                    </div>
                    <div className="d-flex flex-column align-items-start gap-4">
                      <p className="font-size-sm mb-0">
                        Integrate Bridge Scheduling with the Bridge Pay module
                        to facilitate co-pays when an appointment is scheduled.
                        With Bridge Pay, patients can check their insurance
                        coverage in real-time for appointments and services at
                        your office.
                      </p>
                      <IconButton
                        className="c-icon-button__big"
                        href="/patient-payments/"
                      >
                        Learn More About Bridge Pay{' '}
                        <FaArrowRight size={16} className="ms-2" />
                      </IconButton>
                    </div>
                    <div className="d-flex flex-column align-items-start gap-3">
                      <p className="font-size-sm">
                        <strong>
                          Bridge Scheduling and all of Bridge Interact’s
                          modules:
                        </strong>
                      </p>
                      <IconList className="d-flex flex-column gap-4 m-0">
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>
                                Fully integrate with{' '}
                                <a href="/integration-services/">
                                  any EHR, RCM, PM, HIE, and RIS system
                                </a>
                              </strong>{' '}
                            </>
                          }
                        />
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>
                                Can be fully incorporated into your preexisting
                                website
                              </strong>{' '}
                            </>
                          }
                        />
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>
                                Comply with all{' '}
                                <a href="/blog/healthcare-application-security-how-to-protect-patient-data/">
                                  HIPAA security regulations
                                </a>
                              </strong>{' '}
                            </>
                          }
                        />
                        <IconListItem
                          iconType="checkedGreenBullet"
                          text={
                            <>
                              <strong>
                                Can be customized and white-labeled for your
                                brand
                              </strong>{' '}
                            </>
                          }
                        />
                      </IconList>
                      <IconButton
                        className="c-icon-button__big mt-3"
                        href="/patient-engagement-solution/"
                      >
                        Learn More <FaArrowRight size={16} className="ms-2" />
                      </IconButton>
                    </div>
                  </div>
                </BigCardItem>
              </BigCard>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col>
              <div className="d-flex flex-column gap-4">
                <div>
                  <h2 className="fw-bold">
                    The Most Powerful Self-Scheduling Solution for Total
                    Integration, Connection, and Consolidation
                  </h2>
                  <p className="font-size-sm">
                    Bridge Scheduling matches the competition’s capabilities
                    today, while giving you the flexibility to build a fully
                    integrated, scalable platform for tomorrow.
                  </p>
                </div>
                {isDesktop ? (
                  <TableWrapper>
                    <TableHead>
                      <TableHeadItem
                        titleTag
                        title="Scheduling"
                        colSpan={5}
                        className="bg-light"
                      />
                      <TableHeadItem
                        title="Bridge"
                        colSpan={1}
                        className="text-white bg-secondary"
                      />
                      <TableHeadItem
                        title="InteliChart"
                        colSpan={1}
                        className="bg-gray-200"
                      />
                      <TableHeadItem
                        title="Phreesia"
                        colSpan={1}
                        className="bg-gray-200"
                      />
                      <TableHeadItem
                        title="Relatient"
                        colSpan={1}
                        className="bg-gray-200"
                      />
                    </TableHead>
                    <TableBody>
                      <TableBodyRow>
                        <TableBodyItem
                          title="Online Scheduling Software"
                          text="Ability for patients to view and request appointments online without live staff assistance."
                          colSpan={5}
                          className="pe-4"
                        />
                        <TableBodyItem
                          className="text-center align-content-center bg-secondary bg-opacity-10"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                        <TableBodyItem
                          className="text-center align-content-center"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                        <TableBodyItem
                          className="text-center align-content-center"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                        <TableBodyItem
                          className="text-center align-content-center"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                      </TableBodyRow>
                      <TableBodyRow>
                        <TableBodyItem
                          title="Automated Appointment Reminders"
                          text="Automatically-generated appointment reminders (SMS and/or email)."
                          colSpan={5}
                          className="pe-4"
                        />
                        <TableBodyItem
                          className="text-center align-content-center bg-secondary bg-opacity-10"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                        <TableBodyItem
                          className="text-center align-content-center"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                        <TableBodyItem
                          className="text-center align-content-center"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                        <TableBodyItem
                          className="text-center align-content-center"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                      </TableBodyRow>
                      <TableBodyRow>
                        <TableBodyItem
                          title="Patient Self Scheduling (incl. Custom Workflows and Website-based Scheduling)"
                          text="Ability for patients to self-schedule on a wide variety of appointment types supporting different scheduling workflows based on location, provider and appointment type."
                          colSpan={5}
                          className="pe-4"
                        />
                        <TableBodyItem
                          className="text-center align-content-center bg-secondary bg-opacity-10"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                        <TableBodyItem
                          className="text-center align-content-center"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                        <TableBodyItem
                          className="text-center align-content-center"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                        <TableBodyItem
                          className="text-center align-content-center"
                          colSpan={1}
                          icon
                          iconType="check"
                        />
                      </TableBodyRow>
                    </TableBody>
                  </TableWrapper>
                ) : (
                  <TableDropdown className="mb-3" title="Scheduling">
                    <TableDropdownItem
                      title="Online Scheduling Software"
                      text="Ability for patients to view and request appointments online without live staff assistance."
                    >
                      <TableWrapper>
                        <TableBody>
                          <TableBodyRow>
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                          </TableBodyRow>
                        </TableBody>
                      </TableWrapper>
                    </TableDropdownItem>
                    <TableDropdownItem
                      title="Automated Appointment Reminders"
                      text="Automatically-generated appointment reminders (SMS and/or email)."
                    >
                      <TableWrapper>
                        <TableBody>
                          <TableBodyRow>
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                          </TableBodyRow>
                        </TableBody>
                      </TableWrapper>
                    </TableDropdownItem>
                    <TableDropdownItem
                      title="Patient Self Scheduling (incl. Custom Workflows and Website-based Scheduling)"
                      text="Ability for patients to self-schedule on a wide variety of appointment types supporting different scheduling workflows based on location, provider and appointment type."
                    >
                      <TableWrapper>
                        <TableBody>
                          <TableBodyRow>
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                            <TableBodyItem
                              className="text-center align-content-center"
                              colSpan={1}
                              icon
                              iconType="check"
                            />
                          </TableBodyRow>
                        </TableBody>
                      </TableWrapper>
                    </TableDropdownItem>
                  </TableDropdown>
                )}
                <p className="font-size-sm mb-0 mt-3">
                  Bridge Scheduling offers all the robust features of other
                  leading self-scheduling tools. We also provide something they
                  don’t: complete integration with a unified platform that
                  transforms how your practice operates. All of Bridge
                  Interact’s modules work seamlessly to provide total system
                  consolidation and offer the full patient engagement journey.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col>
              <Banner
                title="Experience the difference of a fully consolidated platform."
                label="Request a Demo"
                uri="/view-a-demo/"
                image={banner}
                className="bg-dark-blue"
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section>
        <Container>
          <Row>
            <Col>
              <h2 className="fw-bold">FAQs</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Faqs className="mb-5">
                <FaqSingle question="Can the Bridge Scheduling module support dynamic logic?">
                  <p className="font-size-sm">
                    Yes.{' '}
                    <a href="/patient-scheduling-software/">
                      Bridge Scheduling
                    </a>{' '}
                    supports appointment self-scheduling with dynamic logic.
                  </p>
                  <p className="font-size-sm">
                    The rule-based patient scheduler ensures that patients can
                    work through the proper clinical screening and select from
                    conflict-free times.
                  </p>
                  <p className="font-size-sm mb-0">
                    Patient self-scheduling software was built to handle complex
                    scheduling decision trees and is adaptable to each
                    provider’s unique schedule.
                  </p>
                </FaqSingle>
                <FaqSingle question="Can I create custom patient intake forms?">
                  <p className="font-size-sm">
                    Yes. Bridge offers pre-built intake forms that can be easily
                    customized to your specific requirements.
                  </p>
                  <p className="font-size-sm">
                    There is a small hourly charge for creating a{' '}
                    <a href="/patient-intake-software/">custom intake form</a>.
                    Most custom forms take just a few hours to complete.
                  </p>
                  <p className="font-size-sm mb-0">
                    As well as intake forms, BridgeInteract allows you to create
                    Health Risk Assessments (HRA), surveys, pre-op forms, and
                    more.
                  </p>
                </FaqSingle>
                <FaqSingle question="How can I initially populate the BridgeInteract modules with my EHR/PM software data?">
                  <p className="font-size-sm">
                    Our team is available to make the setup easy and seamless.
                    We’ll help transfer data from your existing portal, EHR, or
                    PM system.
                  </p>
                  <p className="font-size-sm">
                    A typical setup of BridgeInteract with preexisting EHR/PM
                    data looks like this:
                  </p>
                  <ul>
                    <li className="font-size-sm">
                      <strong>Create patient portal accounts:</strong> This can
                      be done quickly using a CSV file or by connecting directly
                      to your EHR/PM system.
                    </li>
                    <li className="font-size-sm">
                      <strong>Import data:</strong> We can import Continuity of
                      Care Documents (CCDs) and pull appointment details,
                      documents, invoices, and more.
                    </li>
                    <li className="font-size-sm">
                      <strong>Provider setup:</strong> Providers can be
                      automatically created within the system.
                    </li>
                  </ul>
                  <p className="font-size-sm mb-0">
                    Experts on the Bridge team will walk you through every step,
                    from migration to go-live, ensuring a smooth transition.
                  </p>
                </FaqSingle>
                <FaqSingle question="Does BridgeInteract offer automated patient notifications?">
                  <p className="font-size-sm">
                    Yes. BridgeInteract has a robust admin panel that allows for
                    the easy setup of notifications.
                  </p>
                  <p className="font-size-sm">
                    Bridge offers interactive voice response (IVR) reminders and
                    notifications,{' '}
                    <a href="/patient-messaging-software-solution/">
                      bidirectional patient messaging
                    </a>
                    , push-based messaging, text messages, and email
                    notifications/reminders.
                  </p>
                  <p className="font-size-sm">
                    Yes. BridgeInteract has a robust admin panel that allows for
                    the easy setup of notifications.
                  </p>
                  <p className="font-size-sm mb-0">
                    Reminders can be used for a variety of features, including:
                  </p>
                  <ul>
                    <li className="font-size-sm">
                      <a href="/patient-appointment-reminder-solution/">
                        Appointment Confirmations
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-messaging-software-solution/">
                        Patient Recall Messaging Software
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/blog/how-to-send-automated-hipaa-compliant-text-message-appointment-reminders/">
                        HIPAA-compliant Appointment Reminders
                      </a>
                    </li>
                    <li className="font-size-sm">Health Summaries</li>
                    <li className="font-size-sm">New Documents</li>
                    <li className="font-size-sm">
                      <a href="/prescription-rx-refill-renewal-request/">
                        Rx Refill Status Change
                      </a>
                    </li>
                    <li className="font-size-sm">New Invoices</li>
                    <li className="font-size-sm">
                      <a href="/lab-results-patient-portal/">New Lab Results</a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/patient-messaging-solution/">New Message</a>
                    </li>
                  </ul>
                  <p className="font-size-sm mb-0">Learn more:</p>
                  <ul>
                    <li className="font-size-sm">
                      <a href="/blog/the-facts-about-hipaa-and-email-sms-communication-with-patients/">
                        The Facts About HIPAA And Email/SMS Communication With
                        Patients
                      </a>
                    </li>
                    <li className="font-size-sm">
                      <a href="/blog/how-to-send-automated-hipaa-compliant-text-message-appointment-reminders/">
                        How To Send Automated Hipaa Compliant Text Message
                        Appointment Reminders
                      </a>
                    </li>
                  </ul>
                </FaqSingle>
              </Faqs>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default PatientSchedulingSoftware;
