import React from 'react';
import { string, node } from 'prop-types';
import cx from 'classnames';

const propTypes = {
  icon: string.isRequired,
  children: node.isRequired,
  className: string,
};

const defaultProps = {
  className: undefined,
};

const IconText = ({ icon, children, className }) => {
  const classes = cx(
    'c-icon-text d-flex flex-column align-items-center text-center gap-3 px-4 px-md-5',
    className,
  );

  return (
    <div className={classes}>
      <img src={icon} alt="" />
      <div>{children}</div>
    </div>
  );
};

IconText.propTypes = propTypes;
IconText.defaultProps = defaultProps;

export default IconText;
